import { CatalogModule } from './page/catalog/catalog.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginModule } from './componentes/admin/login/login.module';
import { FiltrosModule } from './componentes/filtros-home/filtros-home.module';
import { ShareModuleModule } from './shared/share-module/share-module.module';
import { DashboardModule } from './componentes/admin/dashboard/dashboard.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ShareModuleModule,
    LoginModule,
    FiltrosModule,
    DashboardModule,
    CatalogModule
  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
