import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { filterTypeEnum } from 'src/app/models/filter-type.enum';
import { HttpService } from 'src/app/servicios/http.service';
import { responseInterface } from 'src/app/models/response.interface';
import { filterListInterface } from 'src/app/models/filter-list.interface';
import { filterVersion } from 'src/app/models/filter-versions.enum';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-filtros',
  templateUrl: './filtros.component.html',
  styleUrls: ['./filtros.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FiltrosComponent implements OnInit {
  @Output() listaDeProductos = new EventEmitter<any>();

  public listaProductos: any[];

  //Variables para version 2 de filtros
  public oemAndCross: filterListInterface[];
  public oemAndCrossFullLista: filterListInterface[];
  public oemAndCrossTexto: string;
  //==========================

  public oemLista: filterListInterface [];
  public oemFullLista: filterListInterface [];

  public crossLista: filterListInterface [];
  public crossFullLista: filterListInterface [];

  public groupLista: filterListInterface [];
  public groupFullLista: filterListInterface [];

  public makeLista: filterListInterface [];
  public makeFullLista: any;

  public modelLista: filterListInterface [];
  public modelFullLista: any;

  public oemTexto: string;
  public crossTexto: string;
  public groupTexto: string;
  public makeTexto: string;
  public modelTexto: string;

  public oemColumna: string;
  public crossColumna: string;
  public groupColumna: string;
  public makeColumna: string;
  public modelColumna: string;

  public oemType: filterTypeEnum;
  public crossType: filterTypeEnum;
  public groupType: filterTypeEnum;
  public makeType: filterTypeEnum;
  public modelType: filterTypeEnum;

  public desabilitarModelsInput: string;

  public oemContenedor: boolean;
  public crossContenedor: boolean;
  public groupContenedor: boolean;
  public makeContenedor: boolean;
  public modelContenedor: boolean;

  public groupTags: Array<string>;

  public crossUniqueColumnas: any[];

  public filterVersion: filterVersion;

  public loaderFiltros: boolean;

  public loader: boolean;

  constructor(public httpService: HttpService, private changeDetection: ChangeDetectorRef) {
    this.oemTexto = '';
    this.crossTexto = '';
    this.groupTexto =  '';
    this.makeTexto = '';
    this.modelTexto = '';

    this.oemColumna = '';
    this.crossColumna = '';
    this.groupColumna =  '';
    this.makeColumna = '';
    this.modelColumna = '';

    this.groupTags = [];

    this.oemLista = [];
    this.oemFullLista = [];

    this.crossLista = [];
    this.crossFullLista = [];

    this.groupLista = [];
    this.groupFullLista = [];

    this.makeLista = [];
    this.makeFullLista= [];

    this.modelLista = [];
    this.modelFullLista = [];

    this.oemType = filterTypeEnum.oem;
    this.crossType = filterTypeEnum.cross;
    this.groupType = filterTypeEnum.group;
    this.makeType = filterTypeEnum.make;
    this.modelType = filterTypeEnum.model;

    this.oemContenedor = false;
    this.crossContenedor = false;
    this.groupContenedor = false;
    this.makeContenedor = false;
    this.modelContenedor = false;

    this.desabilitarModelsInput = 'false';
    //La version del filtro sirve para mostrar en el front los filtros oem y cross referen separados o en uno solo
    this.filterVersion = environment.versionfiltros;
    this.oemAndCross = [];
    this.oemAndCrossFullLista = [];
    this.oemAndCrossTexto = '';
    this.loaderFiltros = true;
    this.loader = false;
  }

  ngOnInit() {
    this.consultar_oem_api();
    this.consultar_group_api();
    this.consultar_make_api();
    this.consultar_cross_api();
  }

  seleccionar_opcion(valor, tipoOem, columna, id = null) {
    switch (tipoOem) {
      case this.oemType:
        this.oemTexto = valor;
        this.oemColumna = columna;
        if(id && id === filterTypeEnum.oem) {
          //aqui debera ir la id y al buscar agregarlo en la key correspondiente
          this.oemAndCrossTexto = valor;
          this.oemTexto = valor;
          this.oemColumna = columna;
          this.crossTexto = '';
          this.crossColumna = '';
        }

        if(id && id === filterTypeEnum.cross) {
          //aqui debera ir la id y al buscar agregarlo en la key correspondiente
          this.oemAndCrossTexto = valor;
          this.crossTexto = valor;
          this.crossColumna = columna;
          this.oemTexto = '';
          this.oemColumna = '';
        }

        break;

      case this.crossType:
        this.crossTexto = valor;
        this.crossColumna = columna;
        break;

      case this.groupType:
        if(!this.groupTags.includes(valor)) {
          this.groupTags.push(valor);
          this.groupColumna = columna;
        }
        //Seleccion multiple
        break;

      case this.makeType:
        this.makeTexto = valor;
        // activar modelos
        // Lanzar metodo para buscar sus modelos
        this.consultar_model_api(this.makeTexto);
        break;

      case this.modelType:

        this.modelTexto = valor;
        break;
    }
    this.click_afuera(tipoOem);
  }

  desabilitarModels() {
    this.desabilitarModelsInput = 'false';
    this.modelLista = [];
    this.makeTexto = '';
  }

  abrir_opciones(tipoOem) {
    this.oemContenedor = false;
    this.crossContenedor = false;
    this.groupContenedor = false;
    this.makeContenedor = false;
    this.modelContenedor = false;

    switch (tipoOem) {
      case this.oemType:
        this.oemContenedor = true;
        break;

      case this.crossType:
        this.crossContenedor = true;
        break;

      case this.groupType:
        this.groupContenedor = true;
        break;

      case this.makeType:
        this.makeContenedor = true;
        break;

      case this.modelType:
        this.modelContenedor = true;
        break;
    }
  }

  filtrar_busqueda(tipoOem, textoEscrito) {
    switch (tipoOem) {
      case this.oemType:
        if(this.filterVersion === 0) {
          let filtrarOems = this.oemFullLista.filter((item) => {
            return (item.value.toLowerCase().indexOf(textoEscrito.toLowerCase()) > -1);
          });
          this.oemLista = filtrarOems;
        }

        if(this.filterVersion === 1) {
          this.oemTexto = this.oemAndCrossTexto;
          this.crossTexto = this.oemAndCrossTexto;
          if (textoEscrito.length > 2) {
            this.oemContenedor = true;
          }

          let filtrar= this.oemAndCrossFullLista.filter((item) => {
            return (item.value.toLowerCase().indexOf(textoEscrito.toLowerCase()) > -1);
          });
          this.oemAndCross = filtrar;
        }

        break;

      case this.crossType:
        let filtrarCross = this.crossFullLista.filter((item) => {
          return (item.value.toLowerCase().indexOf(textoEscrito.toLowerCase()) > -1);
        })
        this.crossLista = filtrarCross;
        break;

      case this.groupType:
        let filtrarGroup = this.groupFullLista.filter((item) => {
          return (item.value.toLowerCase().indexOf(textoEscrito.toLowerCase()) > -1);
        })
        this.groupLista = filtrarGroup;
        break;

      case this.makeType:
        let filtrarMake = this.makeFullLista.filter((item) => {
          return (item.toLowerCase().indexOf(textoEscrito.toLowerCase()) > -1);
        })
        this.makeLista = filtrarMake;

        if (this.makeTexto === '') {
          this.modelLista = [];
        }
        break;

      case this.modelType:
        let filtrarModel = this.modelFullLista.filter((item) => {
          return (item.toLowerCase().indexOf(textoEscrito.toLowerCase()) > -1);
        })
        this.modelLista = filtrarModel;
        break;
    }
  }

  eliminarTag(group) {
    this.groupTags = this.groupTags.filter(e => e !== group);
  }

  click_afuera(tipoOem) {
    switch (tipoOem) {
      case this.oemType:
        this.oemContenedor = false;
        break;

      case this.crossType:
        this.crossContenedor = false;
        break;

      case this.groupType:
        this.groupContenedor = false;
        break;

      case this.makeType:
        this.makeContenedor = false;
        break;

      case this.modelType:
        this.modelContenedor = false;
        break;
    }
  }

  // Peticiones al api

  async consultar_oem_api() {
    const endPoint = 'kmxcontroller/search_oem';
    await this.httpService.getRequest(endPoint).subscribe((response: responseInterface) => {
      if(!response.error) {
        this.oemLista = response.mensaje;
        this.oemFullLista = response.mensaje;

        if(this.filterVersion === 1) {
          for (const oem of this.oemLista) {
            oem.id = filterTypeEnum.oem;
            this.oemAndCross.push(oem);
            this.oemAndCrossFullLista.push(oem);
          }
        }
      } else {
        alert(response.mensaje);
      }
    });
  }

  async consultar_cross_api() {
    //Pendiente a consultar que se debe tomar del ecel apra cross
    const endPoint = 'kmxcontroller/search_cross';
    await this.httpService.getRequest(endPoint).subscribe((response: responseInterface) => {
      if(!response.error) {
        this.crossLista = response.mensaje;
        this.crossFullLista = response.mensaje;

        if(this.filterVersion === 1) {
          for (const cross of this.crossLista) {
            cross.id = filterTypeEnum.cross;
            this.oemAndCross.push(cross);
            this.oemAndCrossFullLista.push(cross);
          }
        }

        const columnas = response.mensaje.map(data => {
          return data.head;
        });
        this.crossUniqueColumnas = columnas.filter((v, i, a) => a.indexOf(v) === i);
      } else {
        alert(response.mensaje);
      }
      this.loaderFiltros = false;
      this.changeDetection.detectChanges();
    });
  }

  async consultar_group_api() {
    //Pendiente a consultar que se debe tomar del ecel apra cross
    const endPoint = 'kmxcontroller/search_group';
    await this.httpService.getRequest(endPoint).subscribe((response: responseInterface) => {
      if(!response.error) {
        this.groupLista = response.mensaje;
        this.groupFullLista = response.mensaje;
      } else {
        alert(response.mensaje);
      }
    });
  }

  async consultar_make_api() {
    const endPoint = 'kmxcontroller/search_make';
    await this.httpService.getRequest(endPoint).subscribe((response: responseInterface) => {
      if(!response.error) {
        this.makeLista = response.mensaje;
        this.makeLista.sort();
        this.makeFullLista = response.mensaje;
        this.makeFullLista.sort();
      } else {
        alert(response.mensaje);
      }
    });
  }

  async consultar_model_api(vehiculo) {
    this.desabilitarModelsInput = 'false';
    this.modelTexto = '';
    const endPoint = 'kmxcontroller/search_model';
    const temp = {
      'vehiculo' : vehiculo
    };

    await this.httpService.postRequest(endPoint, temp).subscribe((response: responseInterface) => {
      if(!response.error) {
        // activar input de modelos
        this.modelLista = response.mensaje;
        this.modelFullLista = response.mensaje;
        this.modelLista.sort();
        this.modelFullLista.sort();
        this.desabilitarModelsInput = 'true';
        this.changeDetection.detectChanges();
      } else {
        alert(response.mensaje);
      }
    });
  }

  // Peticion para lanzar busqueda de productos

  public buscar_productos_api() {
    // Enviar el nombre de la columna para hacer ams facil la identificacion
    this.loader = true;
    const endPoint = 'kmxcontroller/search_products';
    const temp = {
      'oem' : {
        columna: this.oemColumna,
        valor: this.oemTexto
      },
      'cross' : {
        columna: this.crossColumna ? this.crossColumna: this.crossUniqueColumnas,
        valor: this.crossTexto
      },
      'group' : {
        columna: this.groupColumna,
        valor: this.groupTags
      },
      'make' : {
        columna: this.makeColumna,
        valor: this.makeTexto
      },
      'model' : {
        columna: this.modelColumna,
        valor: this.modelTexto
      }
    };
    this.httpService.postRequest(endPoint, temp).subscribe((response: responseInterface) => {
      if(!response.error) {
        //Event emmiter
        this.listaProductos = response.mensaje;
        this.listaDeProductos.emit({
          listaProductos: this.listaProductos,
          filtros: temp
        });
        if(this.filterVersion === 1) {
          //this.resetVarsFromRequest();
        }
      } else {
        alert(response.mensaje);
      }
      this.loader = false;
      this.changeDetection.detectChanges();
    });
  }

  public resetVarsFromRequest(): void {
    this.oemTexto = '';
    this.crossTexto = '';
    this.groupTexto =  '';
    this.makeTexto = '';
    this.modelTexto = '';

    this.oemColumna = '';
    this.crossColumna = '';
    this.groupColumna =  '';
    this.makeColumna = '';
    this.modelColumna = '';

    this.groupTags = [];
    this.oemAndCrossTexto = '';
    this.listaProductos = [];
    this.makeLista = this.makeFullLista;
    this.modelLista = [];
    this.listaDeProductos.emit({
      listaProductos: this.listaProductos,
      filtros: []
    });
  }
}
