import { CatalogComponent } from './catalog.component';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import {ScrollingModule} from '@angular/cdk/scrolling';
import { ShareModuleModule } from 'src/app/shared/share-module/share-module.module';
import { FormsModule } from '@angular/forms';
import { ResultsComponent } from './results/results.component';
import { GeneratorComponent } from './generator/generator.component';

const routes: Routes = [
  {
    path: '',
    component: CatalogComponent,
    children: [
      {
        path: 'results',
        component: ResultsComponent
      },
      {
        path: 'generate',
        component: GeneratorComponent
      },
      {
        path: '',
        redirectTo: 'generate',
        pathMatch: 'full'
      }
    ]
  }
];

@NgModule({
  declarations: [
    CatalogComponent,
    ResultsComponent,
    GeneratorComponent
  ],
  imports: [
    CommonModule,
    ScrollingModule,
    ShareModuleModule,
    FormsModule,
    RouterModule.forChild(routes)
  ]
})
export class CatalogModule { }
