
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import {ScrollingModule} from '@angular/cdk/scrolling';
import { LoginComponent } from './login.component';
import { ShareModuleModule } from 'src/app/shared/share-module/share-module.module';
import { FormsModule } from '@angular/forms';
import { HttpService } from 'src/app/servicios/http.service';
import { HttpClientModule } from '@angular/common/http';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent
  }
];

@NgModule({
  declarations: [
    LoginComponent,
  ],
  imports: [
    CommonModule,
    ScrollingModule,
    ShareModuleModule,
    FormsModule,
    HttpClientModule,
    RouterModule.forChild(routes)
  ],
  providers: [
    HttpService
  ]
})
export class LoginModule { }
