import { HttpService } from './../../../servicios/http.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
const pdfCreate = pdfMake;
import { DomSanitizer } from '@angular/platform-browser';
import * as _ from 'lodash';

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss']
})
export class ResultsComponent implements OnInit {
  private filterParams: FilterParams;
  public productList: any;
  public productListFull: any;
  public productsForCatalog: any;
  private crossSelectedArray: string[];
  private logoUrlBase64: any;
  public formatProductList: any[];
  private lineUrlBase64: any;
  private highLineUrlBase64: any;
  private backgroundBase64: any;
  public isDisabledBtn: boolean;
  public loadingFlag: boolean;
  public carsUnique;

  constructor(
    private readonly httpService: HttpService,
    private readonly activeRoute: ActivatedRoute,
    private readonly domSanitizer: DomSanitizer
  ) {
    this.productList = [];
    this.productsForCatalog = [];
    this.productListFull = [];
    this.crossSelectedArray = [];
    this.logoUrlBase64 = '';
    this.lineUrlBase64 = '';
    this.highLineUrlBase64 = '';
    this.backgroundBase64 = '';
    this.formatProductList = [];
    this.isDisabledBtn = false;
    this.loadingFlag = false;
    this.carsUnique = [];
  }

  ngOnInit() {
    this.getUrlParams();
  }

  private getUrlParams(): void {
    this.activeRoute.queryParams.subscribe((routes: Params) => {
      if (routes) {
        this.filterParams = routes;
        this.loadingFlag = true;
        this.getResultsFromApi();
        this.getLogoInBase64();
      }
    });
  }

  private getLogoInBase64() {
    const currentDomain = `${window.location.origin}/assets/images/logo.png`;
    const lineDomain = `${window.location.origin}/assets/images/line.png`;
    const highLineDomain = `${window.location.origin}/assets/images/high_line.png`;
    const backgroundDomain = `${window.location.origin}/assets/images/BG_large.jpg`;

    this.toDataURL(currentDomain)
    .then((result: string) => {
      this.logoUrlBase64 = result.toString();
    }).catch(err => console.error(err));

    this.toDataURL(lineDomain)
    .then((result: string) => {
      this.lineUrlBase64 = result.toString();
    }).catch(err => console.error(err));

    this.toDataURL(highLineDomain)
    .then((result: string) => {
      this.highLineUrlBase64 = result.toString();
    }).catch(err => console.error(err));

    this.toDataURL(backgroundDomain)
    .then((result: string) => {
      this.backgroundBase64 = result.toString();
    }).catch(err => console.error(err));
  }

  private getResultsFromApi(): void {
    const endPoint: string = `kmxcontroller/catalog_search`;
    this.httpService.postRequest(endPoint, this.filterParams).subscribe(response => {

      if (!response.error) {
        this.productList = _.sortBy(response.mensaje, o => o.product);
        this.productList.map(product => product.checked = false );
        this.productList.map(product => product.carsForPDf = [] );
        this.productList.map((product, index) => product.id = index );
        this.productListFull = this.productList;
        this.productList[0].crossSelected.forEach(element => {
          this.crossSelectedArray.push(Object.keys(element)[0]);
        });
      } else {
        alert('sin resultados');
      }

      this.loadingFlag = false;
    });
  }

  public openCarsContainer(item): void {
    item.openCars = !item.openCars;
  }

  public checkedProduct(checked: boolean, product): void {
    product.checked = checked;
    if (checked) {
      this.productsForCatalog.push(product);
      this.openCarsContainer(product);
    } else {
      this.productsForCatalog = this.productsForCatalog.filter(productCatalog => productCatalog.id !== product.id);
      this.openCarsContainer(product);
    }
  }

  public checkedCar(checked: boolean, car, products): void {
    if (checked) {
      products.carsForPDf.push(car);
    } else {
      products.carsForPDf = products.carsForPDf.filter(productCatalog =>
      `${productCatalog.brand}${productCatalog.model}${productCatalog.motor}${productCatalog.year}` !== `${car.brand}${car.model}${car.motor}${car.year}`);
    }
  }

  public searchInList(textToSearch: any): void {
    const textFormInput: string = textToSearch.target.value;

    let validateValue: boolean;
    let increment: number = 0;
    const filterItems = this.productListFull.filter((item) => {
      if (this.crossSelectedArray[increment] && item[this.crossSelectedArray[increment]]) {
        validateValue = item[this.crossSelectedArray[increment]].toLowerCase().replace(/\s+/g, '').indexOf(textFormInput.toLowerCase()) > -1;
        increment++;
      } else {
        increment = 0;
        return;
      }

      return validateValue;
    });

    this.productList = filterItems;
  }

  public insertProductForCatalog(): void {
    const validateAllItemsWithCars = this.productsForCatalog.find(car => !car.carsForPDf.length );
    if (validateAllItemsWithCars) {
      let refText = '';
      this.crossSelectedArray.forEach(element => {
        refText += validateAllItemsWithCars[element] + ',';
      });
      const alertMsg = 'Las siguientes referencias no tienen vehiculos ' + refText;
      alert(alertMsg);
      return;
    }

    if (!this.productsForCatalog.length) {
      alert('Seleccione un producto.');
      return;
    }

    this.isDisabledBtn = true;

    this.mapCarsForProducts();
  }

  private async mapCarsForProducts() {
    const getCarsList = [];
    const getProductList = [];

    this.productsForCatalog.forEach(element => {
      element.carsForPDf.forEach(cars => {
        const formatText = cars.brand.trim().charAt(0).toUpperCase()  + cars.brand.trim().slice(1).toLowerCase();
        getCarsList.push(formatText);
      });

      getProductList.push(element.product.trim().toLowerCase());
    });

    this.carsUnique = getCarsList.filter((v, i, a) => a.indexOf(v) === i);
    const newObjectCars = [];

    this.carsUnique.forEach(element => {
    element =  element.toLowerCase();
    const getFilterProductsByCar = [];
    this.productsForCatalog.forEach(productCatalog => {
      productCatalog.carsForPDf.forEach(carPdf => {
        if (carPdf.brand.trim().toLowerCase() === element) {
          getFilterProductsByCar.push(productCatalog);
        }
      });
    });

      newObjectCars.push(
        {
          car: element,
          products: getFilterProductsByCar
        }
      );
    });

    const productUnique = getProductList.filter((v, i, a) => a.indexOf(v) === i);
    const newObjectProducts = [];

    productUnique.forEach(element => {
      const getFilterProducts = [];
      this.productsForCatalog.forEach(productCatalog => {
        if (productCatalog.product.trim().toLowerCase() === element) {
          getFilterProducts.push(productCatalog);
        }
      });

      newObjectProducts.push(
          {
            productName: element,
            products: getFilterProducts
          }
        );
    });

    this.uniqueProductsAndVehicles(newObjectCars, newObjectProducts);
  }

  private async uniqueProductsAndVehicles(newObjectCars, newObjectProducts) {
    let ordererObject = [];
    if (this.filterParams.sortBy === 'car') {

      await  this.asyncForEach(newObjectCars , async (element) => {
        const productCar = element.car;
        const productList = _.sortBy(element.products, o => o.product);
        const fixedProductList = [];
        let lastRefAdded = '';
        await  this.asyncForEach(productList , async (productItem) => {
          let concatRefToShow = '';
          const getCars = productItem.cars;
          const productName = productItem.product;
          let increment = 0;
          for (const key in productItem.crossSelected) {
            const keyForSearch = Object.keys(productItem.crossSelected[key])[0];
            if (increment) {
              concatRefToShow += productItem[keyForSearch] ? ` , ${productItem[keyForSearch]}` : '';
            } else {
              concatRefToShow += productItem[keyForSearch] || '';
            }
            increment++;
          }

          let getProductImageBase64;
          const validateImage = productItem.rutaimagen || `${window.location.origin}/assets/images/empty.jpg`;
          if (productItem.rutaimagen) {
            getProductImageBase64 =  await this.toDataURLCodeIgniter(validateImage);
          } else {
            getProductImageBase64 =  await this.toDataURL(validateImage);
          }

          const prepareStack = {
            stack : [
              {
              image: getProductImageBase64,
              width: 100,
              height: 100,
              alignment : 'center',
              margin: [ 0, 20, 0, 0 ]
              },
              {
                image: this.lineUrlBase64,
                width: 140,
                alignment : 'center'
              },
              {
                text: concatRefToShow,
                bold: true,
                alignment : 'center',
                fontSize: 8
              },
              {
                text: productName.toUpperCase(),
                bold: true,
                alignment : 'center',
                color: '#BE1F24',
                fontSize: 8
              }
            ]
          };

          getCars.forEach(element => {
            const getBrand = element.brand.trim().toLowerCase();
            const productCarName = productCar.trim().toLowerCase();
            if (getBrand.includes(productCarName)) {
              prepareStack.stack.push({
                text: `${element.brand} ${element.model} ${element.year}`.toUpperCase(),
                bold: true,
                alignment : 'center',
                color: '#000000',
                fontSize: 6
              });
            }
          });

          if (lastRefAdded !== concatRefToShow) {
            fixedProductList.push(prepareStack.stack);
          }

          lastRefAdded = concatRefToShow;
        });

        element['fixedProductsPdf'] = fixedProductList;
      });

      ordererObject = _.sortBy(newObjectCars, o => o.car);
      this.formatProductList = ordererObject;

    } else {
      await  this.asyncForEach(newObjectProducts , async (element) => {
        let productList = element.products;
        const fixedProductList = [];
        let lastRefAdded = '';

        productList.forEach(fixVehiclesForSort => {
          const getCars = _.sortBy(fixVehiclesForSort.carsForPDf, (item) => (item.brand));
          fixVehiclesForSort['productsRefForSortBy'] = getCars[0].brand;
        });

        productList =  _.sortBy(productList, (item) => (item.productsRefForSortBy));

        await  this.asyncForEach(productList , async (productItem) => {
          let concatRefToShow = '';
          const getCars = _.sortBy(productItem.carsForPDf, (item) => (item.brand));
          const productName = productItem.product;
          let increment = 0;
          for (const key in productItem.crossSelected) {
            const keyForSearch = Object.keys(productItem.crossSelected[key])[0];
            if (increment) {
              concatRefToShow += productItem[keyForSearch] ? ` , ${productItem[keyForSearch]}` : '';
            } else {
              concatRefToShow += productItem[keyForSearch] || '';
            }
            increment++;
          }

          let getProductImageBase64;
          const validateImage = productItem.rutaimagen || `${window.location.origin}/assets/images/empty.jpg`;
          if (productItem.rutaimagen) {
            getProductImageBase64 =  await this.toDataURLCodeIgniter(validateImage);
          } else {
            getProductImageBase64 =  await this.toDataURL(validateImage);
          }

          const prepareStack = {
            stack : [
              {
              image: getProductImageBase64,
              width: 100,
              height: 100,
              alignment : 'center',
              margin: [ 0, 20, 0, 0 ]
              },
              {
                image: this.lineUrlBase64,
                width: 140,
                alignment : 'center'
              },
              {
                text: concatRefToShow,
                bold: true,
                alignment : 'center',
                fontSize: 8
              },
              {
                text: productName.toUpperCase(),
                bold: true,
                alignment : 'center',
                color: '#BE1F24',
                fontSize: 8
              }
            ]
          };

          getCars.forEach(element => {
            prepareStack.stack.push({
              text: `${element.brand} ${element.model} ${element.year}`.toUpperCase(),
              bold: true,
              alignment : 'center',
              color: '#000000',
              fontSize: 6
            });
          });

          if (lastRefAdded !== concatRefToShow) {
            fixedProductList.push(prepareStack.stack);
          }

          lastRefAdded = concatRefToShow;
        });

        element['fixedProductsPdf'] = fixedProductList;
      });

      ordererObject = _.sortBy(newObjectProducts, o => o.productName);
      this.formatProductList = ordererObject;
    }

    this.createPdfHeader(ordererObject);
  }

  private async createPdfHeader(newObjectCars) {
    const documentDefinition = {
      background: [
        {
          image: this.backgroundBase64,
          width: 792,
          absolutePosition: { x: 0, y: 0 }
        }
      ],
      header : this.setHeaderLogo(),
      pageSize: "A4",
      content: [],
      footer: (currentPage, pageCount, pageSize) => {
        return [
          {
            columns: [
              {
                image: this.highLineUrlBase64,
                width: 500, //500
                alignment : 'left',
                margin: [38, 0, 0, 0]
              },
              {
                stack: [
                  {
                    absolutePosition: {
                      x: currentPage < 10 ? 550 : 547,
                      y: -10
                    },
                    canvas: [
                        {
                          type: 'rect',
                          x: 10, y: 10,
                          w: 18, h: 18,
                          r: 4,
                          color: '#BE1F24'
                        }
                      ]
                  },
                  {
                    text: currentPage,
                    alignment: 'right',
                    margin: [0, 4, 24, 0],
                    color: '#ffffff',
                    fontSize: 10
                  }
                ]
              }
            ]
          }
        ];
      }
    };

    newObjectCars.forEach((objectCar, index) => {
      const getCarTitle = this.setHeaderCarName(objectCar.car || objectCar.productName, index);
      const getCarTitleBackground = this.setHeaderTitleBackground();
      if (!index) {
        documentDefinition.content.push(getCarTitleBackground);
        documentDefinition.content.push(getCarTitle);
        documentDefinition.content.push(this.setProductList(objectCar));
      } else {
        documentDefinition.content.push({
          text: '',
          pageBreak : 'before'
        });
        documentDefinition.content.push(getCarTitleBackground);
        documentDefinition.content.push(getCarTitle);
        documentDefinition.content.push(this.setProductList(objectCar));
      }
    });

    setTimeout(() => {
      const generateNameByDate = new Date();
      const fullDateString = generateNameByDate.toISOString();
      const splithours = fullDateString.split('.');
      const createName = `catalog-${splithours[0]}.pdf`;

      pdfCreate.createPdf(documentDefinition).download(createName);
      this.isDisabledBtn = false;
    }, 2000);

  }

  public generateExcelCatalog() {
    if (!this.productsForCatalog.length) {
      alert('Seleccione un producto.');
      return;
    }

    const cleanProducts = [...this.productsForCatalog];

    cleanProducts.forEach((product) => {
      delete product.cars;
      delete product.carsForPDf;
      delete product.checked;
      delete product.crossSelected;
      delete product.description;
      delete product.openCars;
      delete product.description;
    });

    const endPoint = "kmxcontroller/download_excel";
    const temp = {
      product: cleanProducts
    };
    this.isDisabledBtn = true;
    this.httpService.postRequest(endPoint, temp).subscribe(response => {
      if(response.url) {
        window.open(response.url, '_blank');
      }

      this.isDisabledBtn = false;
    }, error => {
      this.isDisabledBtn = false;
      console.log(error)
    });
  }

  //handlers
  private setHeaderLogo() {
    return {
      image: this.logoUrlBase64,
      width: 75,
      margin: [10, 30],
      alignment : 'right'
    };
  }

  private setHeaderCarName(carName: string, index: number) {
    const headerCarObject = {
      table : {
        headerRows: 1,
        body: [
          [
            {
              text: carName.toUpperCase(),
              bold: true,
              fontSize: carName.length > 12 ? 18 : 35,
              alignment: 'left',
              color: 'white',
              border: [true, false, false, false]
            }
          ]
        ]
      },
      layout: {
        vLineColor: function(i, node) {
            return (i === 0 || i === node.table.widths.length) ? '#BE1F24' : '#BE1F24';
        },
        paddingLeft: function(i, node) { return 20; },
        paddingRight: function(i, node) { return 20; },
        paddingTop: function(i, node) { return 12; },
        paddingBottom: function(i, node) { return 12; }
      },
      absolutePosition: {x: 39, y: carName.length > 12 ? 45 :37 }
    };
    headerCarObject.table['pageBreak'] = 'before';
    return headerCarObject;
  }

  private setHeaderTitleBackground() {
    return {
      canvas: [
        {
          type: 'rect',
          x: 0,
          y: 0,
          w: 285,
          h: 60,
          r: 5,
          lineColor: '#BE1F24',
          color: '#BE1F24'
        }
      ]
    };
  }

  private setProductList(objectCar) {
    const tableProductInit = {
      headerRows: 0,
      layout: 'noBorders',
      table: {
        margin: [ 0, 20, 0, 0 ],
        widths: [170,170,170],
        body: []
      }
    };

    //re order arrays and prevent empty cells
    const productList = objectCar.fixedProductsPdf;
    const fixList = this.chunkArray(productList , 3);
    fixList.forEach((fixedList) => {
      tableProductInit.table.body.push(fixedList);
    });

    tableProductInit.headerRows = tableProductInit.table.body.length;

    return tableProductInit;
  }

  // utils

  private async  toDataURLCodeIgniter(imageUrl) {
    return new Promise((resolve, reject) => {
      const endPoint = 'kmxcontroller/sinitize_image';
      this.httpService.postRequest(endPoint, {url: imageUrl}).subscribe(response => {
        resolve(response);
      });
    });
  }

  private async  toDataURL(imageUrl) {
    const res = await fetch(imageUrl);
    //const res = await fetch(imageUrl);
    const blob = await res.blob();
    let convertToString = '';
    let base64Sanitize = '';
    return new Promise((resolve, reject) => {
      const reader  = new FileReader();
      reader.addEventListener("load",  () => {
          convertToString += reader.result as string;
          const getSeparateData = convertToString.split(';');
          const getImageType = getSeparateData[0];
          const getBodyImage = getSeparateData[2];

          if (getBodyImage) {
            const bae64String = getBodyImage.split(',');
            base64Sanitize = getImageType+';'+ bae64String[0] +','+ (this.domSanitizer.bypassSecurityTrustResourceUrl(bae64String[1] ) as any).changingThisBreaksApplicationSecurity;
          } else {
            base64Sanitize = getImageType+';'+ (this.domSanitizer.bypassSecurityTrustResourceUrl(getSeparateData[1] ) as any).changingThisBreaksApplicationSecurity;
          }
          resolve(base64Sanitize);
      }, false);

      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    });
  }

  private chunkArray(myArray, chunk_size){
    let index = 0;
    const arrayLength = myArray.length;
    const tempArray = [];

    for (index = 0; index < arrayLength; index += chunk_size) {
      const myChunk = myArray.slice(index, index+chunk_size);

      if (myChunk.length === 1) {
        myChunk.push(null,null);
      }

      if (myChunk.length === 2) {
        myChunk.push(null);
      }

      tempArray.push(myChunk);
    }

    return tempArray;
  }

  private async asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }

}

interface FilterParams {
  cities?: string;
  cross?: string;
  group?: string;
  pdf?: string;
  sortBy?: string;
}
