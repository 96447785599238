import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators'
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  apiUrl:string = environment.urlApi;

  constructor(private http: HttpClient) { }

  postRequest(endpoint, data): Observable<any> {
    let url = this.apiUrl + endpoint;
    return this.http.post<any>(url, data)
    .pipe(
      catchError(this.handleError('post', []))
    );
  }

  getRequest(endpoint): Observable<any> {
    let url = this.apiUrl + endpoint;
    return this.http.get<any>(url)
    .pipe(
      catchError(this.handleError('get', []))
    );
  }

  putRequest(endpoint, data): Observable<any> {
    let url = this.apiUrl + endpoint;
    return this.http.put<any>(url, data)
    .pipe(
      catchError(this.handleError('put', []))
    );
  }

  // Manejo de errores
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      return  of(error.error as T);
    };
  }
}
