
import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/servicios/http.service';
import { loginInterface } from 'src/app/models/login.interface';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public usuario: string;
  public contrasena: string;

  private token: string;
  private id_usuario: number;

  public isDisabledButton: boolean;
  public textoBoton: string;

  constructor(private httpService: HttpService, private route: Router) {
    this.usuario = '';
    this.contrasena = '';
    this.isDisabledButton = false;
    this.textoBoton = 'Acceder';
  }

  ngOnInit() {
    const existeToken = localStorage.getItem('kmx_token_usuario');
    if(existeToken) {
      // Re dirigir al dashboard
      this.route.navigateByUrl(environment.urlsInternas.dashboard);
    }
  }

  login() {

    if(this.usuario !== '' && this.contrasena !== '') {
      this.isDisabledButton = true;
      this.textoBoton = 'Cargando...';

      const prepareData = {
        usuario: this.usuario,
        contrasena: this.contrasena
      };
      const endPoint = 'login';
      this.httpService.postRequest(endPoint, prepareData).subscribe((response: loginInterface) => {
        this.isDisabledButton = false;
        this.textoBoton = 'Acceder';

        if(response && response.error) {
          alert(response.mensaje);
          return;
        }

        this.token = response.token;
        this.id_usuario = response.id_usuario;

        localStorage.setItem('kmx_token_usuario', this.token);
        localStorage.setItem('kmx_usuario_id', this.id_usuario.toString());

        this.route.navigateByUrl(environment.urlsInternas.dashboard);

      });
    } else {
      alert('Verifique usuario y contraseña no pueden estar vacios.');
    }

  }
}
