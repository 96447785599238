import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public urlLogin: string;
  public urlFiltros: string;
  public catalogUrl: string;

  public renderCerrarSesion: boolean;

  @Output() removerImprimir = new EventEmitter<any>();

  constructor(private route: Router) {
    this.urlLogin = environment.urlsInternas.login;
    this.urlFiltros = environment.urlsInternas.filtros;
    this.renderCerrarSesion = false;
    this.catalogUrl = environment.urlsInternas.catalog;
  }

  ngOnInit() {
    const existeToken = localStorage.getItem('kmx_token_usuario');
    if(existeToken) {
      this.renderCerrarSesion = true;
    }
  }

  navegarUrl(url) {
    this.route.navigateByUrl(url);
  }

  cerrar_sesion() {
    localStorage.removeItem('kmx_token_usuario');
    localStorage.removeItem('kmx_usuario_id');
    //emitir
    //Re dirigir a filters
    this.removerImprimir.emit(false);
    this.renderCerrarSesion = false;
    this.route.navigateByUrl(environment.urlsInternas.filtros);
  }

}
