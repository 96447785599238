import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/servicios/http.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  private fileToUpload: File = null;
  public workSheet: string;

  private fileToUploadZip: File = null;
  public loadingIco :boolean;
  public successIco :boolean;
  public errorIco :boolean;
  public successIcoExcelImagen :boolean;
  public errorIcoExcelImagen :boolean;
  public activarEstado: boolean;
  public activarEstadoImagenes: boolean;z

  public loadingIcoImagenes :boolean;
  public successIcoImagenes :boolean;
  public errorIcoImagenes :boolean;

  constructor(private httpService: HttpService) {
    this.workSheet = 'DATA BASE';
    this.loadingIco = false;
    this.successIco = false;
    this.errorIco = false;
    this.successIcoExcelImagen = false;
    this.errorIcoExcelImagen = false;
    this.activarEstado = false;
    this.activarEstadoImagenes = false;

    this.loadingIcoImagenes = false;
    this.successIcoImagenes = false;
    this.errorIcoImagenes = false;
  }

  ngOnInit() {
  }

  cargar_excel(files: FileList) {
    this.activarEstado = false;
    this.fileToUpload = files.item(0);
  }

  subir_excel_servidor() {
    if(this.fileToUpload) {
      this.loadingIco = true;
      this.activarEstado = true;
      const endPoint = "kmxcontroller/create_data";
      const formData: FormData = new FormData();
      formData.append('file', this.fileToUpload, this.fileToUpload.name);
      formData.append('workSheet', this.workSheet);

      this.httpService.postRequest(endPoint, formData).subscribe(response => {
        if(!response.error) {
          this.successIco = true;

          if(response.images === 1) {
            this.successIcoExcelImagen = true;
          } else if(response.images === 2) {
            this.errorIcoExcelImagen = true;
          }

          alert(response.mensaje);
        } else {
          this.errorIco = true;
          alert(response.mensaje);
        }
        this.loadingIco = false;
      });
    } else {
      alert('Se debe cargar un archivo primero.');
    }
  }

  cargar_zip_imagenes(files: FileList) {
    this.activarEstadoImagenes = false;
    this.fileToUploadZip = files.item(0);
  }

  subir_zip_servidor() {
    if(this.fileToUploadZip) {
      this.loadingIcoImagenes = true;
      this.activarEstadoImagenes = true;
      const endPoint = "kmxcontroller/insert_images";
      const formData: FormData = new FormData();
      formData.append('file', this.fileToUploadZip, this.fileToUploadZip.name);

      this.httpService.postRequest(endPoint, formData).subscribe(response => {
        if(!response.error) {
          this.successIcoImagenes = true;
          alert(response.mensaje);
        } else {
          this.errorIcoImagenes = true;
          alert(response.mensaje);
        }
        this.loadingIcoImagenes = false;
      });
    } else {
      alert('Se debe cargar un archivo primero.');
    }
  }

}
