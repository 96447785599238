
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FiltrosHomeComponent } from './filtros-home.component';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FiltrosComponent } from '../filtros/filtros.component';
import { ListaProductosComponent } from '../lista-productos/lista-productos.component';
import {ScrollingModule} from '@angular/cdk/scrolling';
import { ShareModuleModule } from 'src/app/shared/share-module/share-module.module';
import { NgxTypeaheadModule } from 'ngx-typeahead';
import { ClickOutSideDirective } from 'src/app/shared/clickOutSide.directive';
import { FormsModule } from '@angular/forms';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule} from '@angular/common/http';


const routes: Routes = [
  {
    path: '',
    component: FiltrosHomeComponent
  }
];

@NgModule({
  declarations: [
    FiltrosHomeComponent,
    FiltrosComponent,
    ListaProductosComponent
  ],
  imports: [
    CommonModule,
    ScrollingModule,
    ShareModuleModule,
    NgxTypeaheadModule,
    FormsModule,
    RouterModule.forChild(routes)
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class FiltrosModule { }


