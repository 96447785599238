import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'e-catalog',
    pathMatch: 'full'
  },
  {
    path: 'e-catalog',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./componentes/filtros-home/filtros-home.module').then(m => m.FiltrosModule)
      }
    ]
  },
  {
    path: 'login',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./componentes/admin/login/login.module').then(m => m.LoginModule)
      }
    ]
  },
  {
    path: 'dashboard',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./componentes/admin/dashboard/dashboard.module').then(m => m.DashboardModule)
      }
    ]
  },
  {
    path: 'catalog',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./page/catalog/catalog.module').then(m => m.CatalogModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
