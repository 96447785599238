import { environment } from './../../../environments/environment';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.scss']
})
export class CatalogComponent implements OnInit {

  constructor(
    private readonly route: Router
  ) { }

  ngOnInit() {
    const existeToken = localStorage.getItem('kmx_token_usuario');
    if(!existeToken) {
      this.route.navigateByUrl(environment.urlsInternas.filtros);
    }
  }

}
