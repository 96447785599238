import { Component, OnInit, Input, OnChanges, SimpleChanges, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { tabsEnum } from 'src/app/models/tabs.enum';
import { HttpService } from 'src/app/servicios/http.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-lista-productos',
  templateUrl: './lista-productos.component.html',
  styleUrls: ['./lista-productos.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListaProductosComponent implements OnInit, OnChanges {

  @Input() productosLista: any[];
  @Input() filtrosAplicados: any;
  @Input() removerPrint: any;

  public tabFirst: tabsEnum;
  public tabSecond: tabsEnum;
  public tabThird: tabsEnum;
  public selectedTab: tabsEnum;

  public listaProductos:any = [];
  public productosRender:any = [];
  public newProductoLista:any = [];
  public listafiltrosUsados:any = [];

  public listaVehiculos:any = [];

  public vehiculosAInfoTecnica: boolean;

  public imagenToPopUp: string;
  public activePopup: boolean;
  public flagPopup: number;
  public generateExcelFlag: boolean;

  public productoToResponsivePopup:any = {};
  public activeREsponsivePopup: boolean = false;

  constructor(private changeDetection: ChangeDetectorRef, private httpService: HttpService, private translate: TranslateService) {
    this.tabFirst = tabsEnum.firstTab;
    this.tabSecond = tabsEnum.secondTab;
    this.tabThird = tabsEnum.thirdTab;
    this.selectedTab = this.tabThird;
    this.vehiculosAInfoTecnica = false;
    this.imagenToPopUp = '';
    this.activePopup = false;
    this.flagPopup = 0;
    this.generateExcelFlag = false;
    this.listafiltrosUsados = {};
    translate.setDefaultLang('es');
  }

  ngOnInit() {
    this.listaProductos = [];
  }

  ngOnChanges(simpleChange: SimpleChanges) {
    if(simpleChange['productosLista'] && simpleChange['productosLista']['currentValue']) {
      this.listaProductos = simpleChange['productosLista']['currentValue'];
      this.listafiltrosUsados = simpleChange['filtrosAplicados']['currentValue'];
      this.generateObjectArray(this.listaProductos);
    }
  }

  selectedItemFromTab(item, tabID) {
    switch (tabID) {
      case this.tabFirst:

        item.firstTab = true;
        item.secondTab = false;
        item.thirdTab = false;
        return item;
        this.selectedTab = this.tabFirst;
        break;

      case this.tabSecond:
        item.firstTab = false;
        item.secondTab = true;
        item.thirdTab = false;
        return item;
        this.selectedTab = this.tabSecond;
        break;

      case this.tabThird:
        item.firstTab = false;
        item.secondTab = false;
        item.thirdTab = true;
        // Agrupar vehiculos
        this.agruparVehiculos(item);
        return item;
        this.selectedTab = this.tabThird;
        break;
    }
  }

  cambiarVista() {
    this.vehiculosAInfoTecnica = !this.vehiculosAInfoTecnica;
  }

  generateCustomArray(numbers) {
    return new Array(numbers);
  }

  generateObjectArray(productos) {
    this.newProductoLista = [];
    this.productosRender = [];
    productos.map((data, index) => {
      let temp = {
        vehiculosAInfoTecnica: false,
        imagenResponsive: false,
        index: index,
        responsivePopup: false,
        data: data
      }
      this.productosRender.push(temp);
    });
    this.newProductoLista = [...this.productosRender];
    this.changeDetection.detectChanges();
  }

  agruparVehiculos(product) {
    let garajeArray = {
      marcaUno: [],
      marcaDos: [],
      marcaTres: [],
      marcaCuatro: [],
      marcaCinco: [],
      marcaSeis: [],
      marcaSiete: [],
      marcaOcho: [],
      marcaNueve: [],
      marcaDiez: [],
      marcaOnce: [],
      marcaDoce: [],
      marcaTrece: [],
      marcaCatorce: [],
      marcaQuince: []
    };

    Object.keys(product.data).forEach(function(key) {
      const keyLower = key.toLocaleLowerCase();
      if(product.data[key]) {
        if(keyLower.includes('brand1') || keyLower.includes('model1') || keyLower.includes('year1')) {
          garajeArray.marcaUno.push({
            columna : key,
            valor: product.data[key]
          });
        }

        if(keyLower.includes('brand2') || keyLower.includes('model2') || keyLower.includes('year2')) {
          garajeArray.marcaDos.push({
            columna : key,
            valor: product.data[key]
          });
        }

        if(keyLower.includes('brand3') || keyLower.includes('model3') || keyLower.includes('year3')) {
          garajeArray.marcaTres.push({
            columna : key,
            valor: product.data[key]
          });
        }

        if(keyLower.includes('brand4') || keyLower.includes('model4') || keyLower.includes('year4')) {
          garajeArray.marcaCuatro.push({
            columna : key,
            valor: product.data[key]
          });
        }

        if(keyLower.includes('brand5') || keyLower.includes('model5') || keyLower.includes('year5')) {
          garajeArray.marcaCinco.push({
            columna : key,
            valor: product.data[key]
          });
        }

        if(keyLower.includes('brand6') || keyLower.includes('model6') || keyLower.includes('year6')) {
          garajeArray.marcaSeis.push({
            columna : key,
            valor: product.data[key]
          });
        }
        if(keyLower.includes('brand7') || keyLower.includes('model7') || keyLower.includes('year7')) {
          garajeArray.marcaSiete.push({
            columna : key,
            valor: product.data[key]
          });
        }
        if(keyLower.includes('brand8') || keyLower.includes('model8') || keyLower.includes('year8')) {
          garajeArray.marcaOcho.push({
            columna : key,
            valor: product.data[key]
          });
        }
        if(keyLower.includes('brand9') || keyLower.includes('model9') || keyLower.includes('year9')) {
          garajeArray.marcaNueve.push({
            columna : key,
            valor: product.data[key]
          });
        }
        if(keyLower.includes('brand10') || keyLower.includes('model10') || keyLower.includes('year10')) {
          garajeArray.marcaDiez.push({
            columna : key,
            valor: product.data[key]
          });
        }
        if(keyLower.includes('brand11') || keyLower.includes('model11') || keyLower.includes('year11')) {
          garajeArray.marcaOnce.push({
            columna : key,
            valor: product.data[key]
          });
        }
        if(keyLower.includes('brand12') || keyLower.includes('model12') || keyLower.includes('year12')) {
          garajeArray.marcaDoce.push({
            columna : key,
            valor: product.data[key]
          });
        }
        if(keyLower.includes('brand13') || keyLower.includes('model13') || keyLower.includes('year13')) {
          garajeArray.marcaTrece.push({
            columna : key,
            valor: product.data[key]
          });
        }
        if(keyLower.includes('brand14') || keyLower.includes('model14') || keyLower.includes('year14')) {
          garajeArray.marcaCatorce.push({
            columna : key,
            valor: product.data[key]
          });
        }
        if(keyLower.includes('brand15') || keyLower.includes('model15') || keyLower.includes('year15')) {
          garajeArray.marcaQuince.push({
            columna : key,
            valor: product.data[key]
          });
        }
      }
    });

    this.listaVehiculos = garajeArray;
  }

  descargar_excel() {
    const endPoint = "kmxcontroller/download_excel";
    const temp = {
      product: this.listaProductos
    };
    this.generateExcelFlag = true;
    this.httpService.postRequest(endPoint, temp).subscribe(response => {
      if(response.url) {
        window.open(response.url, '_blank');
      }

      this.generateExcelFlag = false;
      this.changeDetection.detectChanges();
    }, error => {
      this.generateExcelFlag = false;
      console.log(error)
    });
  }

  trackByFn(index, item) {
    return index; // or item.id
  }

  validateObjectKeys(product): boolean {
    let count: number = 0;
    for (const key in product) {
      if (product.hasOwnProperty(key) && !product[key]) {
        count++;
      }
    }

    if(product && (Object.keys(product).length === count)) {
      return false;
    }

    return true;
  }

  reOrderMakeModelsYear(brand, model, year, motor) {
    if(brand && model && year && motor) {
      return [
        brand,
        model,
        year,
        motor
      ];
    } else if(brand && model && year) {
      return [
        brand,
        model,
        year
      ];
    } else if(brand && model){
      return [
        brand,
        model
      ];
    } else if(brand) {
      return [
        brand
      ];
    } else {
      return [];
    }
  }

  validateCarsTitles(data1, data2, data3, data4, data5, data6) {
    return data1 || data2 || data3 || data4 || data5 || data6;
  }

  openPopup(image) {
    this.imagenToPopUp = image;
    this.activePopup = true;
  }

  click_afuera(activePopup) {
    if(this.flagPopup === 0) {
      this.flagPopup = 1;
    } else {
      this.flagPopup = 0;
      this.activePopup = false;
    }
  }

  click_afuera_responsive() {
    if(this.flagPopup === 0) {
      this.flagPopup = 1;
    } else {
      this.flagPopup = 0;
      this.activeREsponsivePopup = false;
      document.getElementsByTagName('body')[0].style.overflow = 'auto';
    }
  }

  remplazarCaracteres(texto, ultimo) {
    let construirPalabras = '';
    if(Array.isArray(texto) && !!texto.length) {
      let index = 0;
      for (const iterator of texto) {
        construirPalabras += ' / ';
        construirPalabras += iterator;
        index++;
      }
    } else if (!!texto.length) {
      construirPalabras += ' / ';

      construirPalabras += texto;
    }
    return construirPalabras;
  }

  imprimirSiExisteUsuario(): boolean {
    const userToken = localStorage.getItem('kmx_token_usuario');
    const userId = localStorage.getItem('kmx_usuario_id');
    return !!userToken && !!userId;
  }

  abrirMasInformacion(producto) {
    this.activeREsponsivePopup = true;
    this.productoToResponsivePopup = producto;
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
  }

  public replaceOldImageUrl(url: string): string {
    return url.replace("kmxinternational", "kgroupcorp");
  }

}
