import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-filtros-home',
  templateUrl: './filtros-home.component.html',
  styleUrls: ['./filtros-home.component.scss']
})
export class FiltrosHomeComponent implements OnInit {
  public urlLogin: string;
  public productos: any [];
  public filtros: any;
  public removerPrint: boolean;

  constructor() {
  }


  ngOnInit() {
  }

  listaProductos(event) {
    this.productos = event.listaProductos;
    this.filtros = event.filtros;
  }

  removerImprimirEvento($event) {
    this.removerPrint = $event;
  }
}
