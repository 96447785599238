
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import {ScrollingModule} from '@angular/cdk/scrolling';
import { DashboardComponent } from './dashboard.component';
import { ShareModuleModule } from 'src/app/shared/share-module/share-module.module';
import { FormsModule } from '@angular/forms';

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent
  }
];

@NgModule({
  declarations: [
    DashboardComponent,
  ],
  imports: [
    CommonModule,
    ScrollingModule,
    ShareModuleModule,
    FormsModule,
    RouterModule.forChild(routes)
  ]
})
export class DashboardModule { }