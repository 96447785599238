import { environment } from './../../../../environments/environment';
import { filterTypeEnum } from './../../../models/filter-type.enum';
import { filterListInterface } from './../../../models/filter-list.interface';
import { responseInterface } from 'src/app/models/response.interface';
import { Router } from '@angular/router';
import { HttpService } from './../../../servicios/http.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-generator',
  templateUrl: './generator.component.html',
  styleUrls: ['./generator.component.scss']
})
export class GeneratorComponent implements OnInit {
  public listCites: filterListInterface[];
  public listCitesFullList: filterListInterface[];
  public listCitesText: string;
  public listCitesType: filterTypeEnum;
  public listCitesContainer: boolean;
  public listCitesTags: Array<string>;

  public groupList: filterListInterface[];
  public groupFullList: filterListInterface[];
  public groupText: string;
  public groupType: filterTypeEnum;
  public groupContainer: boolean;
  public groupTags: Array<string>;

  public crossList: filterListInterface[];
  public crossFullList: filterListInterface[];
  public crossText: string;
  public crossType: filterTypeEnum;
  public crossContainer: boolean;
  public crossTags: Array<string>;

  public pdfFormat: string;

  public sortBy: string;


  constructor(
    private readonly httpService: HttpService,
    private readonly route: Router) {
      this.listCites = [];
      this.listCitesFullList = [];
      this.listCitesText = '';
      this.listCitesType = filterTypeEnum.cites;
      this.listCitesContainer = false;
      this.listCitesTags = [];

      this.groupList = [];
      this.groupFullList = [];
      this.groupTags = [];
      this.groupText = '';
      this.groupType = filterTypeEnum.group;

      this.crossList = [];
      this.crossFullList = [];
      this.crossText = '';
      this.crossType = filterTypeEnum.oem;
      this.crossContainer = false;
      this.crossTags = [];

      this.pdfFormat = 'image';
      this.sortBy = 'product';
    }

  ngOnInit() {
    this.getCitesFromExcel();
    this.getGroup();
    this.getCrossReferences();
  }

  private getCitesFromExcel(): void {
    const endPoint: string = 'kmxcontroller/country_list';
    this.httpService.getRequest(endPoint).subscribe((responseCites: responseInterface) => {
      if (!responseCites.error) {
        const mapCites: filterListInterface[] = [];
        responseCites.mensaje.forEach(city => {
          mapCites.push({head: '', value: city});
        });
        this.listCites = mapCites;
        this.listCitesFullList = mapCites;
      }
    });
  }

  private getGroup(): void {
    const endPoint: string = 'kmxcontroller/search_group';
    this.httpService.getRequest(endPoint).subscribe((response: responseInterface) => {
      if(!response.error) {
        this.groupList = response.mensaje;
        this.groupFullList = response.mensaje;
      }
    });
  }

  private getCrossReferences(): void {
    const endPoint = 'kmxcontroller/search_oem';
    const fixList: filterListInterface[] = [];
    this.httpService.getRequest(endPoint).subscribe((response: responseInterface) => {
      if(!response.error) {
        const oemsList = response.mensaje;
        const columnsOem = oemsList.map(data => data.head );
        const oemUnique = columnsOem.filter((v, i, a) => a.indexOf(v) === i);

        oemUnique.forEach((oems) => {
          fixList.push({head: `oem${oems}`, value: `oem${oems}`});
        });

        const endPointCross = 'kmxcontroller/search_cross';
        this.httpService.getRequest(endPointCross).subscribe((responseCross: responseInterface) => {
          if(!response.error) {
            const crossList = responseCross.mensaje;

            const columnsCross = crossList.map(data => data.head );
            const crossUnique = columnsCross.filter((v, i, a) => a.indexOf(v) === i);

            crossUnique.forEach((cross) => {
              fixList.push({head: cross, value: cross});
            });

            this.crossList = fixList;
            this.crossFullList = fixList;
          }
        });
      }
    });
  }

  public openOptions(itemType: filterTypeEnum): void {
    this.crossContainer = false;
    this.groupContainer = false;
    this.listCitesContainer = false;

    if (itemType === this.crossType) {
      this.crossContainer = true;
    }

    if (itemType === this.groupType) {
      this.groupContainer = true;
    }

    if (itemType === this.listCitesType) {
      this.listCitesContainer = true;
    }
  }

  public filterSearch(itemType: filterTypeEnum, inputText: string): void {

    if (itemType === this.crossType) {
      const crossGroup = this.crossFullList.filter((group: filterListInterface) => group.value.toLowerCase().indexOf(inputText.toLowerCase()) > -1 );
      this.crossList = crossGroup;
    }

    if (itemType === this.groupType) {
      const filterGroup = this.groupFullList.filter((group: filterListInterface) => group.value.toLowerCase().indexOf(inputText.toLowerCase()) > -1 );
      this.groupList = filterGroup;
    }

    if (itemType === this.listCitesType) {
      const listGroup = this.listCitesFullList.filter((group: filterListInterface) => group.value.toLowerCase().indexOf(inputText.toLowerCase()) > -1 );
      this.listCites = listGroup;
    }
  }

  public chooseOption(itemType: filterTypeEnum, optionValue): void {
    if (itemType === this.crossType) {
      if(!this.crossTags.includes(optionValue)) {
        this.crossTags.push(optionValue);
      }
      this.crossText = '';
      this.crossContainer = false;
      this.crossList = this.crossFullList;
    }

    if (itemType === this.groupType) {
      if(!this.groupTags.includes(optionValue)) {
        this.groupTags.push(optionValue);
      }
      this.groupText = '';
      this.groupContainer = false;
      this.groupList = this.groupFullList;
    }

    if (itemType === this.listCitesType) {
      if(!this.listCitesTags.includes(optionValue)) {
        this.listCitesTags.push(optionValue);
      }
      this.listCitesText = '';
      this.listCitesContainer = false;
      this.listCites = this.listCitesFullList;
    }
  }

  public deleteTag(itemType: filterTypeEnum, group: string): void {
    if (itemType === this.crossType) {
      this.crossTags = this.crossTags.filter(e => e !== group);
    }

    if (itemType === this.groupType) {
      this.groupTags = this.groupTags.filter(e => e !== group);
    }

    if (itemType === this.listCitesType) {
      this.listCitesTags = this.listCitesTags.filter(e => e !== group);
    }
  }

  public closeDropdown(itemType: filterTypeEnum): void {
    if (itemType === this.crossType) {
      this.crossContainer = false;
    }

    if (itemType === this.groupType) {
      this.groupContainer = false;
    }

    if (itemType === this.listCitesType) {
      this.listCitesContainer = false;
    }
  }

  public selectedCardSort(sortType: string): void {
    this.sortBy = sortType;
  }

  public goToSearch(): void {
    if (!this.listCitesTags.length) {
      alert('Debe seleccionar mínimo un país');
      return;
    }

    if (!this.crossTags.length) {
      alert('Debe seleccionar mínimo una referencia');
      return;
    }

    this.prepareQueryString();
  }

  private prepareQueryString(): void {
    const queryString =
    `?sortBy=${this.sortBy}&pdf=${this.pdfFormat}&cities=${this.listCitesTags.join()}&group=${this.groupTags.join()}&cross=${this.crossTags.join()}`;
    this.route.navigateByUrl(`${environment.urlsInternas.catalogResults}${queryString}`);
  }
}
